import { template as template_9563523228fe44a98b061474c34ee639 } from "@ember/template-compiler";
const WelcomeHeader = template_9563523228fe44a98b061474c34ee639(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
