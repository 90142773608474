import { template as template_a80e07a456a847ada2c86b6efac2dcfb } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_a80e07a456a847ada2c86b6efac2dcfb(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
