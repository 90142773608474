import { template as template_f8eea1945144470e9a064f9c8a1146e3 } from "@ember/template-compiler";
const FKLabel = template_f8eea1945144470e9a064f9c8a1146e3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
