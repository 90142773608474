import { template as template_5039c774d6cf44f1b79a1fff3d2c4615 } from "@ember/template-compiler";
const FKText = template_5039c774d6cf44f1b79a1fff3d2c4615(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
