import { template as template_b30c637e40834b109b95dde703466ed5 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b30c637e40834b109b95dde703466ed5(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
